import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import classes from './Layout.module.scss'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { ConfigContext } from '../store/ConfigContext'
import Sidebar from './Sidebar'
import { useLocalStorage } from '../hooks/useLocalStorage'
import FooterNavigation from './FooterNavigation'
import { ContentStackContext } from '@jarvis/olex-content-management'
import { useContentStackLivePreview } from '../hooks/useContentStackLivePreview'
import { ContentStackTypes } from '../store/Constants'
import { LayoutDefinition } from './LayoutDefinition'

const Layout = ({ children }) => {
  const {
    states: { footer: footerState, sidebar: sidebarState },
    sku,
    printer,
    paas,
    footer,
    flags,
    pageDataCache,
    tentantType,
    setTenantType,
    header,
    sidebar,
    os,
    isOsSupported,
    showInstallButton,
    isBrowserSupported,
    contentStackCredential,
    currentPath,
    isHpPlus,
    isMobile,
    compatibilityDefinition,
    error,
    setError
  } = useContext(ConfigContext)
  const { client, locale, livePreviewInfo } = useContext(ContentStackContext)
  const [livePreviewEntry, setLivePreviewEntry] = useState(null)
  const { runRedirect } = useContentStackLivePreview()
  const isLoaded = useLocalStorage()

  const sharedContext = {
    sku,
    printer,
    paas,
    pageDataCache,
    flags,
    tentantType,
    setTenantType,
    footer,
    header,
    sidebar,
    os,
    isOsSupported,
    showInstallButton,
    isBrowserSupported,
    contentStackCredential,
    currentPath,
    isHpPlus,
    isMobile,
    compatibilityDefinition,
    error,
    setError
  }

  useEffect(() => {
    const pageData = pageDataCache?.get(
      ContentStackTypes.tde_layout.content_type_uid
    )
    if (pageData) {
      if (!footerState?.nextButtonLabel) {
        footer.setNextButtonLabel(pageData.footer?.next_button)
      }
      if (!footerState?.backButtonLabel) {
        footer.setBackButtonLabel(pageData.footer?.back_button)
      }
    }
  }, [pageDataCache, footer, footerState, sidebarState])

  useEffect(() => {
    if (livePreviewInfo && livePreviewInfo?.contentTypeUid) {
      client
        .ContentType(livePreviewInfo?.contentTypeUid)
        .Entry(livePreviewInfo?.entryUid)
        .language(
          `${locale.language.toLowerCase()}-${locale.country.toLowerCase()}`
        )
        .includeFallback()
        .toJSON()
        .fetch()
        .then((response) => {
          setLivePreviewEntry(response?.[0]?.[0] || response)
        })
    }
  }, [livePreviewInfo, livePreviewInfo?.contentTypeUid])

  useEffect(() => {
    if (isLoaded && livePreviewInfo?.contentTypeUid && livePreviewEntry) {
      runRedirect({
        livePreviewEntry
      })
    }
  }, [isLoaded, livePreviewInfo?.contentTypeUid, livePreviewEntry])

  if (!isLoaded)
    return (
      <div className={classes.progressBackground}>
        <ProgressIndicator
          data-testid="progress"
          appearance="linear"
          behavior="indeterminate"
        />
      </div>
    )

  return (
    <LayoutDefinition
      header={<Header />}
      sidebar={<Sidebar />}
      footer={<FooterNavigation />}
    >
      {children ? (
        children(sharedContext)
      ) : (
        <ProgressIndicator appearance="linear" behavior="indeterminate" />
      )}
    </LayoutDefinition>
  )
}

export default Layout

import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  Paths,
  Errors
} from './Constants'
import { getConfigurationDefinition } from '../utils/Utils'

export class ConfigContextBusiness {
  static redirectsToNotFoundWhenTheSkuIsNotCompatibleWithUSB = ({
    pageDataCache,
    currentPath,
    sku,
    usbPaths,
    navigation,
    setError
  }) => {
    const configurationsPageData = pageDataCache?.get(
      ContentStackTypes.configuration_definitions.content_type_uid
    )
    if (!configurationsPageData || !currentPath || !sku) {
      return
    }
    const skuWithoutUsb = getConfigurationDefinition(
      configurationsPageData.definitions,
      ConfigurationDefinitionsKeys.skuWithoutUsbDefinition
    )

    if ((skuWithoutUsb || []).includes(sku) && usbPaths.includes(currentPath)) {
      if (setError) setError(Errors.sku_incompatible_with_usb)
      navigation.push(Paths.not_found)
    }
  }
}

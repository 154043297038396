import {
  LocalStoreKeys,
  UserOs,
  UserOsRegExs,
  UserOsSupportedVersion
} from '../store/Constants'
import * as detect from 'react-device-detect'

export const LOCAL_STORAGE_KEY = 'traffic-director'
/*
  function to get data from local storage
  local storage data is a stringified JSON object and should be returned as a JSON object
  the root key is 'traffic-director'
  the key passed a parameter is a subkey of 'traffic-director'
  if no key is passed, the entire data from local storage is returned
  if no data is found, empty object is returned
  @returns {object} - the data from local storage
*/
export const getStoreData = (key) => {
  const trafficDirectorData = localStorage.getItem(LOCAL_STORAGE_KEY)
  // trafficDirectorData is a string, parse to json if exists or return empty object
  const trafficDirectorDataObject = trafficDirectorData
    ? JSON.parse(trafficDirectorData)
    : {}
  if (!key) return trafficDirectorDataObject
  return trafficDirectorDataObject[key]
}

/*
function to set data in local storage
@param {string} key - the key to set in local storage
@param {object} data - the data to set in local storage
*/
export const setStoreData = (key, data) => {
  const trafficDirectorData = getStoreData()
  trafficDirectorData[key] = data
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(trafficDirectorData))
}

export const resetLocalStorage = (currentLocale) => {
  setStoreData(LocalStoreKeys.printer_sku, null)
  setStoreData(LocalStoreKeys.localization, currentLocale.toLowerCase())
  setStoreData(LocalStoreKeys.printer, {})
}

export const filterSubTabsByPaasAndHpPlus = ({ subtabs, paas, isHpPlus }) => {
  return subtabs
    .filter((subTab) => !subTab.show_only_when_is_paas || paas)
    .filter((subTab) => {
      return !subTab.show_only_when_is_hp_plus_country || isHpPlus
    })
}

export const getActionAuxParamsForAnalytics = (
  printerModel,
  paas,
  country,
  language,
  os
) => {
  return `printerModel=${printerModel}&paas=${paas}&locale=${country?.toLowerCase?.()}-${language?.toLowerCase?.()}${
    os ? `&os=${os}` : ''
  }`
}

export const checkBrowserCompatibility = (details) => {
  if (details.isSafari === true && parseFloat(details.browserVersion) < 13) {
    return false
  } else if (details.isIE === true && parseFloat(details.browserVersion) < 10) {
    return false
  }
  return true
}

const discoverOsByUserAgent = (
  data,
  userAgent = window.navigator.userAgent.toUpperCase()
) => {
  const DefaultSetup = {
    [UserOs.mac]: data.isMacOs === true,
    [UserOs.ios]:
      data.isIOS === true ||
      data.isIOS13 === true ||
      data.isIPod13 === true ||
      data.isIPhone13 === true ||
      data.isIPad13 === true,
    [UserOs.android]:
      data.isAndroid === true || userAgent.toUpperCase().includes('ANDROID'),
    [UserOs.chrome]: UserOsRegExs.chrome.test(userAgent),
    [UserOs.windows]: data.isWindows === true,
    [UserOs.linux]: UserOsRegExs.linux.test(userAgent)
  }

  const currentOs = Object.keys(DefaultSetup).find((x) => DefaultSetup[x])
  return currentOs || UserOs.unknown
}

export const getOsAndSupportedStatus = (userAgent, compatibilityDefinition) => {
  const details = detect.getSelectorsByUserAgent(userAgent)
  const discoveredOs = discoverOsByUserAgent(details, userAgent)
  const supportedVersion = (compatibilityDefinition || UserOsSupportedVersion)[
    discoveredOs
  ]

  const isBrowserSupported = checkBrowserCompatibility(details)

  return {
    os: discoveredOs,
    isOsSupported:
      supportedVersion.minVersion > -1 &&
      parseFloat(details.osVersion) >= supportedVersion.minVersion,
    showInstallButton: supportedVersion.showInstallButton,
    isBrowserSupported,
    isMobile: details.isMobile === true
  }
}

export const getConfigurationDefinition = (configurationDefinitions, key) => {
  const configurationDefinition = configurationDefinitions?.find(
    (definition) =>
      definition.configuration_key.toLowerCase() === key.toLowerCase()
  )

  if (!configurationDefinition) {
    return null
  }
  return JSON.parse(configurationDefinition?.configuration_value)
}

import React, { useContext } from 'react'
import classes from './LayoutDefinition.module.scss'
import { ConfigContext } from '../store/ConfigContext'

/**
 * @param header {React.ReactElement}
 * @param sidebar {React.ReactElement}
 * @param children {React.ReactElement}
 * @param footer {React.ReactElement}
 * @returns {JSX.Element}
 */
export const LayoutDefinition = ({ header, sidebar, children, footer }) => {
  const { states } = useContext(ConfigContext)
  const layoutMode = !states?.sidebar?.sidebarVisible ? 'landing' : 'onboarding'

  const queryDemo = localStorage.getItem('queryDemo') === 'true'

  return (
    <div
      className={`${classes.layoutDefinition} ${
        classes[`${layoutMode}Layout`]
      }`}
      data-query-demo={queryDemo}
    >
      <div id="side-menu-node" />
      <section className={classes.header}>{header}</section>
      <section className={classes.content}>
        <aside className={classes.sidebar}>{sidebar}</aside>
        <main className={classes.mainContent} data-testid="mfe-content">
          <div className={classes.insideMainContent}>{children}</div>
        </main>
      </section>
      {layoutMode === 'onboarding' && footer}
    </div>
  )
}

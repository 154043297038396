import { useContext, useEffect, useMemo, useState } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  LocalStoreKeys,
  Paths,
  Errors
} from '../store/Constants'
import { PrinterDetailsClient } from '../clients/PrinterDetailsClient'
import { getConfigurationDefinition } from '../utils/Utils'

const ShouldUseMockedPLS = (mockedPLS, stack, printerSKU) => ({
  true: new Promise((res) =>
    res({
      data: JSON.parse(mockedPLS)
    })
  ),
  false: new PrinterDetailsClient(stack).getPrinterDetails(printerSKU)
})

export const useLocalStorage = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [fetchingDetails, setFetchingDetails] = useState(false)
  const {
    stack,
    getStoreData,
    sku,
    setSku,
    printer,
    setPrinter,
    navigation,
    setPaas,
    pageDataCache,
    flags,
    setError
  } = useContext(ConfigContext)

  const mockedPLS = localStorage.getItem('mockedPLS')

  const path = useMemo(
    () => navigation?.location?.pathname?.replace('/', '').replace('/', ''),
    [navigation?.location?.pathname]
  )

  useEffect(() => {
    if (isLoaded && printer) {
      setPaas(
        !printer?.onboardingCapabilities?.allowedClients?.includes('portalSMB')
      )
    }
  }, [printer, isLoaded])

  useEffect(() => {
    if (
      isLoaded ||
      fetchingDetails ||
      !pageDataCache?.get(
        ContentStackTypes.configuration_definitions.content_type_uid
      ) ||
      !flags
    ) {
      return
    }

    if (Object.values(Paths).includes(`/${path}`)) {
      if (Paths.not_found !== `/${path}`) {
        const storedSku = getStoreData(LocalStoreKeys.printer_sku)
        if (storedSku) {
          setSku(storedSku)
        }
        const storedPrinter = getStoreData(LocalStoreKeys.printer)
        setPrinter(storedPrinter)
      }
      setIsLoaded(true)
    } else if (!sku || sku !== path.toUpperCase()) {
      const configurationsPageData = pageDataCache.get(
        ContentStackTypes.configuration_definitions.content_type_uid
      )
      const friendlyUrlMap = getConfigurationDefinition(
        configurationsPageData?.definitions,
        ConfigurationDefinitionsKeys.friendlyUrl
      )

      const mappedSku = friendlyUrlMap?.[path.toLowerCase()]
      if (mappedSku) {
        navigation.push(`/${mappedSku}`)
      }
      setFetchingDetails(true)
      const printerSKU = mappedSku || path.toUpperCase()

      ShouldUseMockedPLS(mockedPLS, stack, printerSKU)
        [!!mockedPLS].then((response) => {
          if (!response.data) {
            navigation.push(Paths.not_found)
            setSku(null)
            setPrinter(null)
            setError(Errors.pls_empty)
          } else {
            setSku(printerSKU)
            setPrinter({
              ...response.data,
              derivativeName: response.data.derivativeName.trim()
            })
          }
        })
        .catch((e) => {
          navigation.push(Paths.not_found)
          console.error(e)
          setSku(null)
          setPrinter(null)
          setError(Errors.pls_error)
        })
        .finally(() => {
          setFetchingDetails(false)
          setIsLoaded(true)
        })
    }
  }, [
    sku,
    printer,
    isLoaded,
    fetchingDetails,
    navigation,
    stack,
    setSku,
    setPrinter,
    setPaas,
    getStoreData,
    pageDataCache,
    flags,
    setError
  ])

  return isLoaded
}
